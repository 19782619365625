import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import AsyncButton from '../../../components/async_button';
import Mapper from '../../../util/mapper';
import DomUtil from '../../../util/dom_util';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Converter from '../../../util/converter'
import ErrorHandler from '../../../util/error_handler';
import { calculateFreightTransactionCharges } from '../../../services/freight_transactions_service'

class FreightCalculator extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      calculation: {
        carrier_percentage_share: 50
      },
      result: undefined,      
    }
  }

  handleChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.calculation[e.target.name] = value;
    this.setState({ calculation: this.state.calculation });
  }

  calculate(e) {
    e.preventDefault();
    var form = e.currentTarget;
    
    DomUtil.disableFormSubmitButtonClass(form);    
    calculateFreightTransactionCharges(this.state.calculation).then((result) => {
      this.setState({ result: result });
      DomUtil.enableFormSubmitButtonClass(form);
    }).catch((error) => {
      ErrorHandler.showError(error);
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }

  getCharge(type) {
    var charges = this.state.result.charges;
    if(charges) {
      return charges.find(c => c.type === type);
    }
  }
  
  getPercentage(amount, fee) {
    return (fee / amount) * 100;
  }

  handleSliderChange(event, newValue) {
    this.state.calculation.carrier_percentage_share = newValue;
    this.setState({ calculation: this.state.calculation });
  }

  render() {
    return (
      <div>
        <form autoComplete="off" onSubmit={this.calculate.bind(this)}>

          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Freight transaction</label>
                </div>
              </div>
            </div>
          
            <div className="row">
              <div className="col-md-4">
                <FormControl
                  variant="outlined"
                  margin="dense"
                  fullWidth>
                  <InputLabel>Currency *</InputLabel>
                  <Select
                    native
                    required
                    label="Currency *"
                    value={this.state.calculation.currency}
                    name="currency"
                    onChange={this.handleChange.bind(this)} >
                      <option key={"currency_empty"} value=""></option>
                      {Object.keys(Mapper.currencies()).map(currency => {
                        return (<option key={"currency_"+currency} value={currency}>{Mapper.fromCurrency(currency)}</option>);
                      })}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <TextField
                  label="Freight amount"
                  variant="outlined"
                  required
                  fullWidth
                  margin="dense"
                  value={this.state.calculation.amount}
                  name="amount"
                  onChange={this.handleChange.bind(this)}
                  InputProps={{
                    inputComponent: Converter.numberFormatter,
                    startAdornment: <InputAdornment position="start">{Mapper.fromCurrencySymbol(this.state.calculation.currency)}</InputAdornment>,
                  }}
                />
                </div>                    
            </div>
            
            <div className="row">
              <div className="col-md-4">
                <Box sx={{ width: 250 }}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={2}>
                      <span>{100 - this.state.calculation.carrier_percentage_share}% Charterer</span>
                    </Grid>
                    <Grid item xs={8}>
                      <Slider
                        step={5}
                        valueLabelDisplay="off"
                        track={false}
                        value={this.state.calculation.carrier_percentage_share}
                        onChange={this.handleSliderChange.bind(this)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <span>{this.state.calculation.carrier_percentage_share}% Carrier</span>
                    </Grid>
                  </Grid>
                </Box>
              </div>
              <div className="col-md-8">
              <span>Please specify the escrow fee share ratio between the Charterer and the Crrier.</span> 
              <br/>
              <span>The escrow fee is deducted from the funds held in escrow before the release to the Carrier. If the escrow fee is (partly) paid by the Charterer, this part of needs to be added to the freight amount.</span> 
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <TextField
                  label="Broker fee"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={this.state.calculation.broker_fee}
                  name="broker_fee"
                  onChange={this.handleChange.bind(this)}
                  InputProps={{
                    inputComponent: Converter.numberFormatter,
                    startAdornment: <InputAdornment position="start">{Mapper.fromCurrencySymbol(this.state.calculation.currency)}</InputAdornment>,
                  }}
                />
                </div>                    
            </div>
            
            <div className="row">
              <div className="col-md">
                <AsyncButton
                  type="submit"
                  variant="outlined"
                  color="primary"
                  icon="check"
                  text="Calculate" />
              </div>
            </div>

            </div>

          </form>

        { this.state.result &&
          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Result</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td>Freight amount</td>
                      <td>{Converter.toCurrency(this.state.result.currency, this.state.result.gross_amount)}</td>
                    </tr>
                    <tr className="row-no-top-border-and-padding">
                      <td>Escrow agent fee*</td>
                      <td>{Converter.toCurrency(this.state.result.currency, this.getCharge("escrow_fee").amount * -1)} ({Converter.toDigits(this.getPercentage(this.state.result.gross_amount, this.getCharge("escrow_fee").amount), 2)}%)</td>
                    </tr>
                    {this.getCharge("broker_fee") &&
                      <tr className="row-no-top-border-and-padding">
                        <td>Broker fee</td>
                        <td>{Converter.toCurrency(this.state.result.currency, this.getCharge("broker_fee").amount * -1)}</td>
                      </tr>
                    }
                    <tr>
                      <td>Carrier receives</td>
                      <td>{Converter.toCurrency(this.state.result.currency, this.state.result.net_amount)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
                <div className="col-md">
                  <span><i>*These charges are an indication and are not legally binding.</i></span>
                </div>
            </div>
            
          </div>
        }
        
      </div >
    );
  }
}

export default FreightCalculator;
