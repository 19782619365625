import { LoginApi, ApiKeysApi } from "generated-authentication-api";
import { checkStatus, getHeaders, handleResponse } from './web_service';
import decode from 'jwt-decode';

export const LOGIN_API = new LoginApi({
  basePath: window.appConfig.API_HOSTNAME
});

export const API_KEY_API = new ApiKeysApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function login(username, password) {
  return LOGIN_API.login({
    email: username,
    password: password
  })
  .then(checkStatus)
  .then(res => {
    var token = res.headers.authorization;
    token = token.replace('Bearer ','');
    setToken(token)
    return Promise.resolve(res);
  }).catch(error => {
    return Promise.reject(error)
  });
}

export function tempLogin(data) {
  return LOGIN_API.tempLogin(data).then(handleResponse);
}

export function forgotPassword(data) {
  return LOGIN_API.forgotPassword(data).then(handleResponse);
}

export function verifyMfa(data) {
  return LOGIN_API.verifyMfa(data, getHeaders()).then(handleResponse);
}

export function sendMfa() {
  return LOGIN_API.sendMfa(null, getHeaders()).then(handleResponse);
}

export function getApiKeys() {
  return API_KEY_API.getApiKeys(getHeaders()).then(handleResponse);
}

export function createApiKey(data) {
  return API_KEY_API.createApiKey(data, getHeaders()).then(handleResponse);
}

export function getApiKey(id) {
  return API_KEY_API.getApiKey(id, getHeaders()).then(handleResponse);
}

export function deleteApiKey(id) {
  return API_KEY_API.deleteApiKey(id, getHeaders()).then(handleResponse);
}

export function loggedIn() {
  const token = getToken()
  return !!token && !isTokenExpired(token)
}

export function getExpiration() {
  const token = getToken()
  return getTokenExpiration(token)
}

export function hasType(types, account_id) {
  if(!account_id) {
    account_id = getAccountId();
  }
  const token = getToken()
  return !!token && !isTokenExpired(token) && tokenHasType(token, account_id, types);
}

export function getType(account_id) {
  if(!account_id) {
    account_id = getAccountId();
  }
  const token = getToken()
  return !!token && !isTokenExpired(token) && getTokenType(token, account_id);
}

export function mfaVerification() {
  const token = getToken();
  if(token) {
    const decoded = decode(token);
    return !decoded.mfa_verified;
  }
  return false;
}

export function actionRequired() {
  const token = getToken();
  if(token) {
    const decoded = decode(token);
    return decoded.actions_required && decoded.actions_required.length > 0;
  }
  return false;
}

export function hasAction(action) {
  const token = getToken();
  if(token) {
    const decoded = decode(token);
    if(decoded.actions_required) {
      return decoded.actions_required.includes(action)
    }
  }
  return false;
}

export function getUserId() {
  const token = getToken();
  if(token) {
    const decoded = decode(token);
    return decoded.sub;
  }
}

export function getAccountIds() {
  const token = getToken();
  if(token) {
    const decoded = decode(token);
    return Object.keys(decoded.accounts);
  }
}

export function isAdmin() {
  return hasRole("admin");
}

export function isDefault() {
  return hasRole("default");
}

export function isAuthorised() {
  return hasRole("authorised");
}

export function isReadOnly() {
  return hasRole("read_only");
}

export function hasRole(role) {
  const token = getToken();
  if(token) {
    return getUserRole(token, getAccountId()) === role;
  }
  return false;
}

export function getUserRole(token, accountId) {
  var account = getAccount(token, accountId);
  if(account) {
    return account[1];
  }
}

export function getTokenExpiration(token) {
  try {
    const decoded = decode(token);
    return decoded.exp;
  }
  catch (err) {
    return Date.now() / 1000;
  }
}

export function isTokenExpired(token) {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    }
    else
      return false;
  }
  catch (err) {
    return false;
  }
}

export function tokenHasType(token, accountId, types) {
  var account = getAccount(token, accountId);
  if(account) {
    var type = account[0];
    return types.includes(type);
  }
  return false;
}

export function getTokenType(token, accountId) {
  var account = getAccount(token, accountId);
  if(account) {
    return account[0];
  }
}

export function getAccount(token, accountId) {
  return decode(token).accounts[accountId];
}

export function setToken(idToken) {
  localStorage.setItem('id_token', idToken)
}

export function getToken() {
  return localStorage.getItem('id_token')
}

export function logout() {
  localStorage.removeItem('id_token');
  localStorage.removeItem('account_id');
}

export function getProfile() {
  return decode(getToken());
}

export function getAccountId() {
  return localStorage.getItem('account_id');
}

export function setAccountId(accountId) {
  if(accountId) {
    localStorage.setItem('account_id', accountId);
  } else {
    localStorage.removeItem('account_id');
  }
}
